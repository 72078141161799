import React from "react";
import theme from "theme";
import { Theme, Text, Box, Button, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Гольф-клуб Oakleaf Greens
			</title>
			<meta name={"description"} content={"Де кожна гойдалка - це нова пригода!"} />
			<meta property={"og:title"} content={"Головна | Гольф-клуб Oakleaf Greens"} />
			<meta property={"og:description"} content={"Де кожна гойдалка - це нова пригода!"} />
			<meta property={"og:image"} content={"https://scanovater.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://scanovater.com/img/3570073.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://scanovater.com/img/3570073.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://scanovater.com/img/3570073.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://scanovater.com/img/3570073.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://scanovater.com/img/3570073.png"} />
			<meta name={"msapplication-TileImage"} content={"https://scanovater.com/img/3570073.png"} />
			<meta name={"msapplication-TileColor"} content={"https://scanovater.com/img/3570073.png"} />
		</Helmet>
		<Components.Eader />
		<Section padding="50px 0 50px 0" sm-padding="80px 0 50px 0" quarkly-title="Hero-11">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					padding="80px 0px 80px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					sm-padding="0 0px 50px 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						padding="7px 20px 7px 20px"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						background="#e9ffee"
						color="#417634"
					>
						<Text margin="0px 10px 0px 0px" color="#417634" font="--base" sm-margin="0px 0 5px 0px">
						Гольф-клуб 
						</Text>
					</Box>
					<Text margin="0px 0px 30px 0px" font="normal 700 42px/1.2 --fontFamily-sans" sm-font="normal 700 36px/1.2 --fontFamily-sans" color="--darkL2">
					Oakleaf Greens
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					У гольф-клубі Oakleaf Greens ми пишаємося тим, що пропонуємо неперевершений досвід гри в гольф в оточенні безтурботної краси ретельно доглянутих зелених лугів і фервеїв. Незалежно від того, чи ви досвідчений професіонал, чи тільки починаєте досліджувати світ гольфу, Oakleaf Greens - це ваше місце для відпочинку та релаксації.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						flex-direction="column"
					>
						<Button
							padding="15px 38px 15px 38px"
							background="#417634"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							letter-spacing="1px"
							margin="0px 0px 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#417634"
							hover-color="--darkL1"
							hover-background="rgba(215, 26, 89, 0.03)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Контакти
						</Button>
					</Box>
				</Box>
				<Image
					src="https://scanovater.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					sm-height="500px"
					height="100%"
					lg-width="100%"
					lg-height="auto"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому варто обрати Oakleaf Greens?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Експертно розроблене поле
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Відчуйте захоплення від гри на полі, розробленому відомими гольф-архітекторами, що пропонує стратегічні завдання для всіх рівнів майстерності.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Виняткові зручності
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Від нашого сучасного клубу до професійного магазину з найновішим спорядженням - все, що вам потрібно, знаходиться тут.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Дружня спільнота
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Приєднуйтесь до спільноти ентузіастів гольфу, які так само захоплені грою, як і ви. В Oakleaf Greens ви не просто член клубу - ви частина сім'ї.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Сталі практики
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Ми прагнемо до сталого розвитку, підтримуючи наш курс за допомогою екологічно чистих практик, які роблять його "зеленим" у всіх сенсах цього слова.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://scanovater.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Про наш клуб
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Розташований серед пагорбів і величних дубів, Oakleaf Greens пропонує втечу на природу, де кожна мить - це більше, ніж просто гольф, це створення спогадів, які триватимуть все життя. Наш клуб оснащений найсучаснішим обладнанням та полем, яке кидає виклик і захоплює в рівній мірі. Тут кожна деталь ретельно продумана, щоб покращити вашу гру і принести вам задоволення.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
					Приєднуйтесь до нас сьогодні!
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
					Відкрийте для себе дух гольфу. Відкрийте для себе Oakleaf Greens!
					</Text>
					<Button
						background="--color-primary"
						padding="12px 28px 12px 28px"
						border-radius="4px"
						font="normal 400 18px/1.5 --fontFamily-sans"
						margin="20px 0px 60px 0px"
						sm-margin="0px 0px 40px 0px"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Звʼязатися з нами
					</Button>
				</Box>
				<Image
					src="https://scanovater.com/img/3.jpg"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 0px -160px"
				sm-flex-direction="column"
				sm-margin="0px 0px 0px 0"
			>
				<Image
					src="https://scanovater.com/img/4.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					margin="80px 0px 0px 0px"
					object-fit="cover"
					sm-margin="0 0px 0px 0px"
				/>
				<Image
					src="https://scanovater.com/img/5.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
				/>
				<Image
					src="https://scanovater.com/img/6.jpg"
					display="block"
					width="100%"
					border-radius="25px"
					object-fit="cover"
					margin="0px 0px 80px 0px"
					sm-margin="0px 0px 0 0px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});